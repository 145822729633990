import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  justify-content: space-between;
  align-items: center; 
  margin: auto;
  background-color: #ffffff;
  overflow:hidden;

   @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  text-align: center;
  margin: 50px 0 20px 0;
  width: 100%; 

  img {
    max-width: 180px;
    height: auto;
  }

  @media all and (orientation: landscape) {
     width: 100%; 
     margin: 20px 0;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap:20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  font-size: 1.6em;
  padding: 0 1rem;
  line-height: 1.5;  
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StarWrapper = styled("span")<{ selected: boolean }>(({ selected }) => ({
  margin: "0 0.5rem",
  '& svg path': {
    fill: selected ? "#00934a" : "#cccccc",
    fillOpacity: 1,
  }
}));

export const SkipButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 90px !important;

   @media all and (orientation: landscape) {
    margin-top: 20px; 
    margin-bottom: 10px;
  }
  
`;

export const SkipButton = styled.button`
  color: gray;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.5em;
  background-color: transparent;
  border:none;
`;
