import React, {
  useState,
  useCallback,
  useRef,
  ReactNode,
  useMemo,
} from "react";
import {
  attachRequestInterceptor,
  detachRequestInterceptor,
} from "../../lib/API";
import Messaging, { OnMessageCallback } from "../../lib/messaging";
import CallContext from "./CallContext";
import {
  TwilioVriCallPayload,
  VcoMutePayload,
  VcoUnMutePayload,
  IncomingMessageEventMap,
  UserState,
  Initialize,
  InitializeOptions,
  QrAuthRequest,
  VIBusyPayload,
  DeviceRegisterPayload,
  VriRequestRatingPayload,
  UUID,
} from "../../../types";
import { authLogin } from "../../../services/qr-auth.service";
import { useToast } from "src/context/ToastContext";
import LocalStorage from "../../lib/local-storage";
import StorageContants from "src/config/storage.constants";
import { getBusinessListById } from "src/services/qr-business-details.service";
import {
  AuthLoginState,
  Business,
  Destroy,
  CallState,
  MakeCall,
  HangupCall,
  RejectCall,
  JoinCall,
  WebTokenFlagHandler,
  CallRatingState,
} from "./types";
import Messages from "src/config/messages.constants";
import {
  muteVoiceConference,
  unmuteVoiceConference,
} from "../../../services/qr-voice-conference.service";
import qrMakeCall from "src/services/qr-make-call.service";
import generateDeviceId from "src/lib/generateDeviceId";
import qrRejectCall from "src/services/qr-reject-call.service";
import qrRequestById from "src/services/qr-requestbyid.service";
import qrHangupCall from "src/services/qr-hangup.service";
import * as Sentry from "@sentry/react"; // Import Sentry
import { useTwilioUserMediaContext } from "../TwilioUserMediaContext";
import { TOKEN_EXPIRED } from "../../config/constants";

export const defaultUserState: UserState = {
  userId: null,
  deviceId: null,
  phoneNumber: null,
};

export type OnIncomingMessageEventCallback = <
  T extends keyof IncomingMessageEventMap
>(
  event: T,
  message: IncomingMessageEventMap[T]
) => void;

const messaging = new Messaging({
  messagingType: "websocket",
});

interface CallProviderProps {
  children: ReactNode;
}

export const defaultCallState: CallState = {
  requestId: null,
  requestType: "VRI",
  requestStatus: null,
  roomName: null,
  region: null,
  twilioRoomId: null,
  twilioVideoToken: null,
  twilioVoiceToken: null,
  toPhoneNumber: null,
  connected: false,
  isConnecting: false,
  isJoining: false,
  isIncomingCall: false,
  isOutgoingCall: false,
  interpreterName: null,
  interpreterNRPCDNumber: null,
  interpreterId: null,
  callerName: null,
  callerNumber: null,
  vco: false,
  ringTimeout: 15,
  providerName: null,
  callDuration: 0,
  calleeName: null,
  calleeNumber: null,
  position: null,
  transferInterpreterName: null,
  transferInterpreterId: null,
  callEnded: null,
};

export const defaultCallRatingState: CallRatingState = {
  isRatingAvailable: false,
  ratingDetail: null,
};
const CallProvider: React.FC<CallProviderProps> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const requestInterceptorIdRef = useRef<number>(-1);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [authLoginResponse, setAuthLoginResponse] =
    useState<AuthLoginState | null>(null);
  const toast = useToast();
  const [refreshedAccessToken, setRefreshedAccessToken] = useState<
    string | null
  >(null);
  const [business, setBusiness] = useState<Business | null>(null);
  const [isBusinessFetched, setIsBusinessFetched] = useState(false);
  const [callState, setCallState] = useState<CallState>(defaultCallState);
   const [callRatingState, setCallRatingState] = useState<CallRatingState>(defaultCallRatingState);
  const [isCameraTurnedOffBeforeCall] = useState(false);
  const [isCallRejected, setIsCallRejected] = useState(false);
  const [apiErrorFlag, setApiErrorFlag] = useState(false);
  const [isWebTokenExpired, setIsWebTokenExpired] = useState(false);

  const {
    isMediaPermissionDenied,
    isMediaStopped,
    startMedia,
    audioDeviceId,
    videoDeviceId,
    destroyMedia,
  } = useTwilioUserMediaContext();

  const verifyMediaAvailable = useCallback(async () => {
    if (isMediaPermissionDenied) {
      toast.error(Messages.CAMERA_NOT_CONNECTED);
      return false;
    }

    if (isMediaStopped && audioDeviceId && videoDeviceId) {
      const isMediaStarted = await startMedia({
        type: "exact",
        audioDeviceId,
        videoDeviceId,
      });

      if (!isMediaStarted) {
        toast.error(Messages.CAMERA_NOT_CONNECTED);
      }
      return isMediaStarted;
    }
    return true;
  }, [
    isMediaPermissionDenied,
    isMediaStopped,
    audioDeviceId,
    videoDeviceId,
    toast,
    startMedia,
  ]);

  const clearError = () => {
    setErrorMessage(null);
    setApiErrorFlag(false);
  };

  const getAuthenticationToken = useCallback(
    async (authRequestBody: QrAuthRequest) => {
      try {
        const { data, error } = await authLogin(authRequestBody);
        if (data) {
          setAuthLoginResponse({
            businessId: data.user?.businessId,
            token: data.user?.token,
            uuid: data.user?.uuid,
          });
        }
        if (error) {
          toast.error(error);
          setApiErrorFlag(true);
          setErrorMessage(error);
        }
      } catch (error: any) {
        toast.error(error);
        setApiErrorFlag(true);
        setErrorMessage(error);
      }
    },
    [toast]
  );

  const setTwilioVriPayload = useCallback((payload: TwilioVriCallPayload) => {
    setCallState((state) => ({
      ...state,
      interpreterName: payload.interpreterName,
      interpreterNRPCDNumber: payload.NRPCDNumber,
      providerName: payload.providerName,
      roomName: payload.mid,
      requestId: payload.requestID,
      interpreterId: payload.assignedInterpreter,
      requestStatus: payload.requestStatus,
      ringTimeout: payload.ringtimeout,
      isConnecting: false,
      isJoining: true,
      requestType: payload.requestType,
      twilioVideoToken: payload.twilioVideoToken,
      transferInterpreterId: null,
      transferInterpreterName: null,
      vco: true,
    }));
  }, []);

  const setToggleDeafAudiofromInterpreter = useCallback(
    (vco: boolean, roomName: UUID) => {
      if (vco) {
        unmuteVoiceConference({ roomname: roomName });
      } else {
        muteVoiceConference({ roomname: roomName });
      }
    },
    []
  );

  const setVriCallEndedByInterpreter = useCallback((payload: VriRequestRatingPayload) => {
    console.log(payload.requestStatus);
    setCallState((state) => ({
      ...state,
      ...defaultCallState,
      callEnded: true,
    }));
    setCallRatingState((state) => ({
      ...state,
      isRatingAvailable: true,
      ratingDetail: {
        callDuration: payload.callDuration,
        providerName: payload.providerName,
        requestId: payload.requestID,
        interpreterName: payload.interpreterName,
        interpreterNRPCDNumber: payload.NRPCDNumber,
        interpreterId: payload.assignedInterpreter,
        requestStatus: payload.requestStatus,
      },
    }));
  }, []);
  
  const setVCO = useCallback((vco: boolean, roomName: UUID) => {
    setCallState((state) => ({ ...state, vco, roomName }));
  }, []);

  const setVIBusy = useCallback((payload: VIBusyPayload) => {
    setCallState((state) => ({ ...state, ...defaultCallState }));
    setErrorMessage(payload.body);
  }, []);

  const onIncomingMessageEvent: OnIncomingMessageEventCallback = useCallback(
    (event, message) => {
      switch (event) {
        case "DEVICE_REGISTER":
          attachRefreshedToken((message as DeviceRegisterPayload).api_token);
          break;
        case "TWILIO_VRI_CALL":
          setTwilioVriPayload(message as TwilioVriCallPayload);
          break;
        case "VCO_MUTE":
          setVCO(false, (message as VcoMutePayload).mid);
          setToggleDeafAudiofromInterpreter(
            false,
            (message as VcoMutePayload).mid
          );
          break;
        case "VCO_UNMUTE":
          setVCO(true, (message as VcoUnMutePayload).mid);
          setToggleDeafAudiofromInterpreter(
            true,
            (message as VcoUnMutePayload).mid
          );
          break;
        case "VI_BUSY":
          setVIBusy(message as VIBusyPayload);
          break;
        case "VRI_REQUEST_RATING":
          setVriCallEndedByInterpreter(message as VriRequestRatingPayload);
          break;

        default:
          break;
      }
    },
    [
      setToggleDeafAudiofromInterpreter,
      setTwilioVriPayload,
      setVCO,
      setVIBusy,
      setVriCallEndedByInterpreter,
    ]
  );

  const handleMessage: OnMessageCallback = useCallback(
    (event, message) => {
      switch (event) {
        case "notification_enabled":
          break;
        case "message":
          onIncomingMessageEvent(message.push_type, message);
          break;
        default:
          break;
      }
    },
    [onIncomingMessageEvent]
  );

  const attachRefreshedToken = (token: string) => {
    LocalStorage.set(StorageContants.ACCESS_TOKEN, token);
    LocalStorage.set(StorageContants.AUTH_API_TOKEN, token);
    setRefreshedAccessToken(token);
    detachRequestInterceptor(requestInterceptorIdRef.current);
    requestInterceptorIdRef.current = attachRequestInterceptor({
      authToken: token,
    });
  };

  const destroy = useCallback<Destroy>(() => {
    if (messaging) {
      messaging.destroy();
      setCallState(defaultCallState);
      setIsBusinessFetched(false);
    }

    setInitialized(false);
    setCallState(defaultCallState);
    setCallRatingState(defaultCallRatingState);
    detachRequestInterceptor(requestInterceptorIdRef.current);
    requestInterceptorIdRef.current = -1;
  }, []);

  const init = useCallback<Initialize>(
    ({ userId, authToken, deviceId }: InitializeOptions) => {
      if (!initialized) {
        requestInterceptorIdRef.current = attachRequestInterceptor({
          authToken,
        });
        messaging.init({ userId, authToken, deviceId });
        messaging.onMessage(handleMessage);
        messaging.onError(setErrorMessage);
        setInitialized(true);
      }
    },
    [initialized, requestInterceptorIdRef, handleMessage]
  );

  const setBusinessDetails = useCallback(
    async (businessId: string) => {
      const result = await getBusinessListById(businessId);
      try {
        if (result?.data?.businessList) {
          setBusiness(result?.data?.businessList[0]);
          setIsBusinessFetched(true);
        }
      } catch (error: any) {
        toast.error(error);
        setApiErrorFlag(true);
        setErrorMessage(error);
        // Capture an error when fetching business details
        Sentry.captureException(error);
      }
    },
    [toast]
  );

  const webTokenHandler = useCallback<WebTokenFlagHandler>(
    (tokenflag: boolean) => {
      setIsWebTokenExpired(tokenflag);
    },
    [setIsWebTokenExpired]
  );

  const makeCall = useCallback<MakeCall>(
    async ({ phoneNumber, specialityTag = "" }) => {
      const isMediaAvailable = await verifyMediaAvailable();
      if (!isMediaAvailable) {
        return;
      }
      const deviceId = generateDeviceId();
      if (phoneNumber && deviceId) {
        setCallState((state) => ({
          ...state,
          phoneNumber,
          isConnecting: true,
        }));

        const { data, error } = await qrMakeCall({
          fromNumber: phoneNumber,
          specialityTags: specialityTag,
          deviceId,
          isQRRequest: true,
        });

        if (data) {
          const { callDetail } = data;
          LocalStorage.set(StorageContants.REQUEST_ID, callDetail.requestId);
          setCallState((state) => ({
            ...state,
            region: callDetail.region,
            requestId: callDetail.requestId,
            requestType: callDetail.requestType,
            roomName: callDetail.roomname,
            twilioRoomId: callDetail.roomid,
            twilioVideoToken: callDetail.token,
            isConnecting: false,
            isOutgoingCall: true,
            isJoining: true,
            connected: false,
            position: callDetail.position,
          }));
        }

        if (error) {
          if (error === TOKEN_EXPIRED) {
            setErrorMessage(Messages.TOKEN_ERROR);
            setApiErrorFlag(true);
            webTokenHandler(true);
            setCallState(defaultCallState);
          } else {
            setErrorMessage(error);
            setApiErrorFlag(true);
            setCallState(defaultCallState);
          }

          // Capture an error when making a call

          Sentry.captureException(error);
        }
      }
    },
    [verifyMediaAvailable, webTokenHandler]
  );

  const hangup = useCallback<HangupCall>(async () => {
    if (callState.roomName) {
      const { data, error } = await qrHangupCall({
        roomname: callState.roomName,
      });

      if (data) {
        destroyMedia();
        setCallState((state) => ({
          ...state,
          ...defaultCallState,
          callEnded: true,
        }));
      }

      if (error) {
        setErrorMessage(error);
        setApiErrorFlag(true);
        // Capture an error when hanging up a call
        Sentry.captureException(error);
      }
    }
  }, [callState.roomName, destroyMedia]);

  const disconnect = useCallback(() => {
    setCallState(defaultCallState);
  }, []);

  const getVideoInterpreter = useCallback(
    (phoneNumber: string) => {
      setCallState((state) => ({
        ...state,
        isConnecting: true,
      }));
      makeCall({ specialityTag: "vi", phoneNumber: phoneNumber });
    },
    [makeCall]
  );

  const setVco = useCallback((vco: boolean) => {
    setCallState((state) => ({ ...state, vco }));
  }, []);

  const reject = useCallback<RejectCall>(async () => {
    const request_id = callState.requestId
      ? callState.requestId
      : LocalStorage.get(StorageContants.REQUEST_ID);
    const auth_token = authLoginResponse?.uuid
      ? authLoginResponse?.uuid
      : LocalStorage.get(StorageContants.AUTH_API_TOKEN);
    if (request_id && auth_token) {
      const { data, error } = await qrRejectCall({
        requestId: request_id,
        participantId: auth_token,
      });
      if (data) {
        destroyMedia();
        setIsCallRejected(true);
        setCallState((state) => ({ ...state, ...defaultCallState }));
      } else {
        Sentry.captureMessage(
          "reject call response came null ending call manually"
        );
        destroyMedia();
        setIsCallRejected(true);
        setCallState((state) => ({ ...state, ...defaultCallState }));
      }

      if (error) {
        setErrorMessage(error);
        setApiErrorFlag(true);
        // Capture an error when rejecting a call
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage("reject call error is null");
      }
    } else {
      let message = "";
      if (!callState.requestId) {
        message = "request id is empty";
      }
      if (!authLoginResponse?.uuid) {
        message += " & auth uuid id is empty";
      }
      Sentry.captureMessage(message);
    }
  }, [callState.requestId, authLoginResponse?.uuid, destroyMedia]);

  const join = useCallback<JoinCall>(async () => {
    if (callState.requestId) {
      const { data, error } = await qrRequestById({ id: callState.requestId });
      if (data) {
        const { callRequest } = data;

        if (callState.roomName === callRequest.meetingId) {
          setCallState((state) => ({
            ...state,
            interpreterId: callRequest.assignedInterpreter,
            isConnecting: false,
            isJoining: false,
            connected: true,
          }));
        } else {
          setErrorMessage("Invalid Call Request");
          setApiErrorFlag(true);
          setCallState((state) => ({ ...state, ...defaultCallState }));
        }
      }
      if (error) {
        setErrorMessage(error);
        // Capture an error when joining a call
        Sentry.captureException(error);
      }
    }
  }, [callState]);

  const callRejectedStatus = useCallback(() => {
    setIsCallRejected(false);
    setApiErrorFlag(false);
  }, [setIsCallRejected]);

  const contextValue = useMemo(
    () => ({
      init,
      destroy,
      getAuthenticationToken,
      authLoginResponse,
      refreshedAccessToken,
      business,
      setBusinessDetails,
      isBusinessFetched,
      makeCall,
      hangup,
      disconnect,
      reject,
      getVideoInterpreter,
      error: errorMessage,
      clearError,
      setVco,
      ...callState,
      ...callRatingState,
      isCameraTurnedOffBeforeCall,
      isCallRejected,
      join,
      callRejectedStatus,
      apiErrorFlag,
      isWebTokenExpired,
      webTokenHandler,
    }),
    [
      init,
      destroy,
      getAuthenticationToken,
      authLoginResponse,
      refreshedAccessToken,
      business,
      setBusinessDetails,
      isBusinessFetched,
      makeCall,
      hangup,
      disconnect,
      reject,
      getVideoInterpreter,
      errorMessage,
      setVco,
      callState,
      callRatingState,
      isCameraTurnedOffBeforeCall,
      isCallRejected,
      join,
      callRejectedStatus,
      apiErrorFlag,
      isWebTokenExpired,
      webTokenHandler,
    ]
  );

  return (
    <CallContext.Provider value={contextValue}>{children}</CallContext.Provider>
  );
};

export default CallProvider;
