import ConvoUS from "../src/assets/Logo/ConvoUS.svg";
import SignLiveUK from "../src/assets/Logo/SignLiveUK.svg";
import AddressBarUS from "../src/assets/Help/Android/Android-Chrome-AddressBar.png";
import AddressBarUK from "../src/assets/Help/Android/Android-Chrome-AddressBarUK.png";
import PermissionSettingsUS from "../src/assets/Help/Android/Android-Chrome-Menu.png";
import PermissionSettingsUK from "../src/assets/Help/Android/Android-Chrome-MenuUK.png";
import PermissionOffUS from "../src/assets/Help/Android/Android-Chrome-SettingsCamMicOff.png";
import PermissionOffUK from "../src/assets/Help/Android/Android-Chrome-SettingsCamMicOffUK.png";
import PermissionOnUS from "../src/assets/Help/Android/Android-Chrome-SettingsCamMicOn.png";
import PermissionOnUK from "../src/assets/Help/Android/Android-Chrome-SettingsCamMicOnUK.png";
import AllowControlsUS from "../src/assets/Help/IOS/iOS-Chrome-PermissionDialog.png";
import AllowControlsUK from "../src/assets/Help/IOS/iOS-Chrome-PermissionDialogUK.png";
import AddressBarSafariUS from "../src/assets/Help/IOS/iOS-Safari-AddressBar.png";
import AddressBarSafariUK from "../src/assets/Help/IOS/iOS-Safari-AddressBarUK.png";
import DenyAllowUS from "../src/assets/Help/IOS/iOS-Safari-CamMicSettingsDenied.png";
import DenyAllowUK from "../src/assets/Help/IOS/iOS-Safari-CamMicSettingsDeniedUK.png";
import AllowCamSettingsUS from "../src/assets/Help/IOS/iOS-Safari-CamSettingAllow.png";
import AllowMicSettingsUS from "../src/assets/Help/IOS/iOS-Safari-MicSettingAllow.png";
import AllowCamSettingsUK from "../src/assets/Help/IOS/iOS-Safari-CamSettingAllowUK.png";
import AllowMicSettingsUK from "../src/assets/Help/IOS/iOS-Safari-MicSettingAllowUK.png";

type ConfigType = {
  [key: string]: {
    appName: string;
    companyName: string;
    region: string;
    appLogoImageSrc: string;
    addressBarImageSrc: string;
    permissionSettingImageSrc: string;
    permissionOffImageSrc: string;
    permissionOnImageSrc: string;
    allowControlsImageSrc: string;
    addressBarSafariImageSrc: string;
    denyAllowImageSrc: string;
    allowCamSettingsImageSrc: string;
    allowMicSettingsImageSrc: string;
    appLogoImageHeight: number;
    ratingScreenText: string
  };
};

const Config: ConfigType = {
  US: {
    appName: "Convo Now",
    companyName: "Convo",
    region: "US",
    appLogoImageSrc: ConvoUS,
    addressBarImageSrc: AddressBarUS,
    permissionSettingImageSrc: PermissionSettingsUS,
    permissionOffImageSrc: PermissionOffUS,
    permissionOnImageSrc: PermissionOnUS,
    allowControlsImageSrc: AllowControlsUS,
    addressBarSafariImageSrc: AddressBarSafariUS,
    denyAllowImageSrc: DenyAllowUS,
    allowCamSettingsImageSrc: AllowCamSettingsUS,
    allowMicSettingsImageSrc: AllowMicSettingsUS,
    appLogoImageHeight: 25,
    ratingScreenText: "How was your experience using Convo today?"
  },
  AU: {
    appName: "Convo Now",
    companyName: "Convo",
    region: "AU",
    appLogoImageSrc: ConvoUS,
    addressBarImageSrc: AddressBarUS,
    permissionSettingImageSrc: PermissionSettingsUS,
    permissionOffImageSrc: PermissionOffUS,
    permissionOnImageSrc: PermissionOnUS,
    allowControlsImageSrc: AllowControlsUS,
    addressBarSafariImageSrc: AddressBarSafariUS,
    denyAllowImageSrc: DenyAllowUS,
    allowCamSettingsImageSrc: AllowCamSettingsUS,
    allowMicSettingsImageSrc: AllowMicSettingsUS,
    appLogoImageHeight: 25,
    ratingScreenText: "How was your experience using Convo today?"
  },
  UK: {
    appName: "SignLive",
    companyName: "SignLive",
    region: "UK",
    appLogoImageSrc: SignLiveUK,
    addressBarImageSrc: AddressBarUK,
    permissionSettingImageSrc: PermissionSettingsUK,
    permissionOffImageSrc: PermissionOffUK,
    permissionOnImageSrc: PermissionOnUK,
    allowControlsImageSrc: AllowControlsUK,
    addressBarSafariImageSrc: AddressBarSafariUK,
    denyAllowImageSrc: DenyAllowUK,
    allowCamSettingsImageSrc: AllowCamSettingsUK,
    allowMicSettingsImageSrc: AllowMicSettingsUK,
    appLogoImageHeight: 30,
    ratingScreenText: "How was your experience using Sign Live today?"
  },
};

export default Config;
