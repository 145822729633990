import axios from 'axios';
import { APIResponse, APIResult } from '../types';
import END_POINT from '../config/endpoints.constants';

export interface CallRatingRequestPayload {
    requestId: string;
    rating: number;
    comment: string;
    providerId: string;
    callQualityRating: number;
    callQualityComment: string;
}

export interface CallRatingResult extends APIResult {}

export interface CallRatingResponse extends APIResponse {
    data?: object;
}

export const createCallRating = async (payload: CallRatingRequestPayload) => {
    const result = {} as CallRatingResult;
    try {
        await axios.post<CallRatingResponse>(END_POINT.QR_CALL_RATING,payload);
    } catch (error) {
        result.error = error;
    }
    return result;
};
