import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCall } from "../../call-sdk/context/CallContext";
import { useTwilioUserMediaContext } from "src/call-sdk/context/TwilioUserMediaContext";
import { localTrackToMediaStream } from "../../util/local-track.util";
import useVideoTrack from "../../hooks/useVideoTrack";
import RemoteParticipantVideo from "../remoteParticipantVideo/RemoteParticipantVideo";
import { SelfViewContainer } from "../selfView/VideoContainerStyles";
import { Fab, CircularProgress } from "@mui/material";
import CancelIcon from "../../assets/Room/cancel.svg";
import Video_Off from "../../assets/Room/video-off.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { useRoomContext } from "src/call-sdk/context/RoomContext";
import ErrorBoundary from "../ErrorBoundary";
import useParticipants from "src/hooks/useParticipants";
import { datadogRum } from "@datadog/browser-rum";
import { ConferanceRoomContainer , LocalVideo , ButtonContainer } from "./ConferanceRoomStyles";

const ConferanceRoom = () => {
  const { vco, hangup, callEnded} = useCall();
  const { destroyMedia } = useTwilioUserMediaContext();
  const { room } = useRoomContext();
  const participants = useParticipants(room);
  const videoTrack = useVideoTrack();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleHangup = useCallback(() => {
    if (room) {
      room.localParticipant.videoTracks.forEach((video) => {
        if (!video.track.isEnabled) {
          video.track.enable();
        }
      });
      datadogRum.addAction("call hanged up navigating to ( /qr-feedback )");
      hangup();
      room.disconnect();
    }
  }, [room, hangup]);

  useEffect(() => {
    if (callEnded) {
      datadogRum.addAction("call ended navigating to ( /qr-feedback )");
      destroyMedia();
      navigate({ pathname: "/qr-feedback" }, { replace: true });
    }
  }, [callEnded, destroyMedia, navigate]);

  useEffect(() => {
    if (room) {
      room.localParticipant.audioTracks.forEach((publication) => {
        if (vco) {
          publication.track.enable();
        } else {
          publication.track.disable();
        }
      });
    }
  }, [room, vco]);

  const handleIncomingMessage = useCallback((text: string) => {
    console.log("Interpreter Message: ", text);
  }, []);

  const localVideoElement = useMemo(() => {
    if (videoTrack?.isEnabled) {
      return (
        <SelfViewContainer>
          <LocalVideo
            mediaStream={localTrackToMediaStream(videoTrack)}
            className="local-video-container"
            resize
          />
        </SelfViewContainer>
      );
    } else {
      return (
        <SelfViewContainer>
          <img src={Video_Off} alt="No Camera" style={{ height: "8vh" }} />
        </SelfViewContainer>
      );
    }
  }, [videoTrack]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    // Log when participants are added (debugging)
    console.log("Waiting for participants...");

    if (participants.length > 0) {
      console.log("Participants are available now.");
      clearTimeout(timer);
      setLoading(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [participants, setLoading]);

  const participantVideos = useMemo(() => {
    if (loading) {
      return (
        <div className={"remote-video-container"}>
          <CircularProgress size={100} color="success" />
        </div>
      );
    } else if (participants.length > 0) {
      return (
        <div
          className={
            participants.length > 1
              ? "team-video-container"
              : "remote-video-container"
          }
        >
          {participants.map((participant) => (
            <RemoteParticipantVideo
              key={participant.sid}
              participant={participant}
              onMessage={handleIncomingMessage}
              viewStyle={
                participants.length > 1 ? "viewStyleTeam" : "viewStyleSingle"
              }
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className={"remote-video-container"}>
          <p>No video available</p>
        </div>
      );
    }
  }, [participants, handleIncomingMessage, loading]);

  return (
    <ErrorBoundary>
      <ConferanceRoomContainer>
        {localVideoElement}
        {participantVideos}
        <ButtonContainer>
          <Fab
            style={{ backgroundColor: "red" }}
            className="cancelBtn"
            onClick={handleHangup}
          >
            <LazyLoadImage src={CancelIcon} />
          </Fab>
        </ButtonContainer>
      </ConferanceRoomContainer>
    </ErrorBoundary>
  );
};

export default ConferanceRoom;
